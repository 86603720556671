import React from 'react';
import { RedocStandalone } from 'redoc';
import { useRoutes, RouteObject, Link, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import documentations, { Documentations } from './backend/documentations';
import './css/App.css';
import './css/Redoc.css';

function App() {

  const [searchParams] = useSearchParams();
  const isAdmin = searchParams.get(`show`) === process.env.REACT_APP_DOCUMENTATION_ADMIN;

  const { data, isFetching } = useQuery<Documentations.Documentation[]>({
    queryKey: [`documentations`],
    queryFn: () => documentations.listDocumentations({ enabled: true }),
  });


  const routes: RouteObject[] = [];

  if (!isFetching && data) {
    routes.push({
      path: `/`,
      element: <div className='menu-container'>
        {data.filter((d) => isAdmin || d.public).map((d) => <Link key={d.id} to={`/${d.uuid}`} className='menu-item'>
          <img className="menu-logo" src={d.logo} alt={d.name} />
        </Link>)}
      </div>
    },
      ...data.map((d) => ({
        path: `/${d.uuid}`,
        element: <RedocStandalone
          specUrl={d.path}
          options={{ hideDownloadButton: true, disableSearch: true, jsonSampleExpandLevel: `all` }} />
      })));
  }

  const router = useRoutes(routes);
  return <div className=''>
    {router}
  </div>;
}

export default App;
