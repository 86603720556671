import axios from "axios";


class Documentations {

  private readonly configuration = {
    baseURL: process.env.REACT_APP_DOCUMENTATION_SERVER as string,
    headers: { "X-Access-Token": process.env.REACT_APP_DOCUMENTATION_TOKEN as string }
  };

  public readonly getDocumentation = async (id: number): Promise<any> => {
    try {
      const response = await axios.get(`/api/documentations/${id}`, this.configuration);
      return response.data;
    } catch (e) { throw e; }
  };

  public readonly listDocumentations = async (params: Partial<Documentations.Documentation>): Promise<any> => {
    try {
      const response = await axios.post(`/api/documentations/list`, params, this.configuration);
      return response.data;
    } catch (e) { throw e; }
  };

  public readonly deleteDocumentation = async (id: number): Promise<any> => {
    try {
      const response = await axios.delete(`/api/documentations/${id}`, this.configuration);
      return response.data;
    } catch (e) { throw e; }
  };

}

export namespace Documentations {

  export type Documentation = {
    id: number,
    uuid: string,
    name: string,
    description?: string,
    path: string,
    logo: string,
    public: boolean,
    enabled: boolean,
  };

};

const documentations = new Documentations();
export default documentations;